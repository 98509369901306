import React from "react"
import * as R from "ramda"
import { graphql, Link, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"
import { BgImage } from "gbimage-bridge"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"

import * as styles from "./hero.module.scss"

const Hero = ({ pageContent }) => {
  const { vector, a } = useStaticQuery(
    graphql`
      {
        vector: file(relativePath: { eq: "Vector.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        a: file(relativePath: { eq: "Group 257.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2983
              height: 428
              quality: 100
              layout: FIXED
            )
          }
        }
      }
    `
  )
  const vectorImage = R.path(["childImageSharp", "gatsbyImageData"], vector)
  const bgaddImage = R.path(["childImageSharp", "gatsbyImageData"], a)

  const header = R.path(["header"], pageContent)
  const contentText = R.path(
    ["mainImageContentText", "mainImageContentText"],
    pageContent
  )
  const mainImage = R.path(["mainImage", "file", "url"], pageContent)
  const mainImageCtaButtonText = R.path(["mainImageCtaButtonText"], pageContent)
  const mainImageCtaButtonAction = R.path(
    ["mainImageCtaButtonSlug"],
    pageContent
  )

  return (
    <Box
      className={styles.herobgaddimagecont}
      style={{
        overflow: "hidden",
        backgroundColor: "#FFECA7",
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <img
        className="!absolute top-0 left-0 w-full h-full object-cover hidden md:block"
        src={mainImage}
      />
      <Box
        style={{
          maxWidth: "1240px",
          width: "100%",
          padding: "0px 20px",
          margin: "0 auto",
        }}
      >
        <BgImage image={vectorImage} className={styles.vectorimage}>
          <Container className={styles.container}>
            <h1>{header}</h1>
            <ReactMarkdown children={contentText} />
            <Link className="blue" to={`${mainImageCtaButtonAction}`}>
              <Button variant="contained" color="primary">
                {mainImageCtaButtonText}
              </Button>
            </Link>
          </Container>
        </BgImage>
      </Box>
    </Box>
  )
}

export default Hero
